import React from 'react';
import Text from 'antd/lib/typography/Text';

import TableColEntityGrowth from 'components/Table/TableCell/TableColEntityGrowth';
import TableColFormatted from 'components/Table/TableCell/TableColFormated';
import TableColDate from 'components/Table/TableCell/TableColDate';
import Identifier from 'components/Common/Identifier';
import TableEditableCell from 'components/Table/TableCell/TableEditableCell';
import BackgroundWrapper from 'components/Common/BackgroundWrapper';
import SelectorCell from 'components/Table/TableCell/SelectorCell';
import capitalize from 'lodash/capitalize';
import toUpper from 'lodash/toUpper';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import StatusWrapper from 'components/Common/StatusWrapper';
import TableColProgressBar from 'components/Table/TableCell/TableColProgressBar';
import { roundToTwoDecimals } from './roundToTwoDecimals';

export const TABLE_DATA_INDEX = {
  ACCOUNT_NAME: 'accountName',
  ACCOUNT_LOGIN: 'accountLogin',
  COMMISSION: 'commission',
  COMMISSION_GROWTH: 'commissionGrowth',
  LEADS_GROWTH: 'registrationsGrowth',
  TRADES_GROWTH: 'tradesGrowth',
  CLOSE_DATE: 'closeDate',
  CLOSE_PRICE: 'closePrice',
  CREATED_AT: 'createdAt',
  FIRST_COMMISSION_DATE: 'firstCommissionDate',
  VERIFICATION_STATUS: 'verificationStatus',
  UPDATED_AT: 'updatedAt',
  DIRECTION: 'direction',
  OPEN_PRICE: 'openPrice',
  OPEN_DATE: 'openDate',
  PROFIT: 'profit',
  TICKET: 'ticket',
  TYPE: 'type',
  VOLUME: 'volume',
  VALUE: 'value',
  SWAP: 'swaps',
  SYMBOL: 'symbol',
  DATE: 'date',
  NAME: 'name',
  FULL_NAME: 'fullName',
  PARTNER: 'name',
  METHOD: 'method',
  AMOUNT: 'amount',
  ID: 'id',
  COUNTRY: 'country',
  ACCOUNT_COUNTRY: 'accountCountry',
  BS_USER_COUNTRY: 'bsUserCountry',
  FIRST_DATE: 'firstDate',
  TRADE_DATE: 'tradeDate',
  FIRST_DEPOSIT: 'firstDepositAmount',
  STATUS: 'status',
  USER_STATUS: 'userStatus',
  BS_USER_STATUS: 'bsUserStatus',
  IB_COMMISSION: 'ibCommission',
  USER_EMAIL: 'userEmail',
  OPEN_TIME: 'openTime',
  CLOSE_TIME: 'closeTime',
  SIZE: 'size',
  OPEN: 'open',
  PRICE: 'price',
  TAX_INVOICE_APPROVAL_REQUIRED: 'isTaxInvoiceApprovalRequired',
  COMMISSION_PERCENT: 'commissionPercent',
  ORDER: 'order',
  LOGIN: 'login',
  AUTHOR: 'author',
  MESSAGE: 'message',
  FTD: 'ftd',
  SOURCE_TYPE: 'type',
  CREATED: 'createdAt',
  LAST_LOGIN: 'lastLoggedAt',
  MANAGER: 'managerId',
  GROUP: 'groupId',
  GROUP_CODE: 'groupCode',
  EARNINGS: 'earnings',
  LEADS: 'leads',
  REGISTERED: 'registered',
  REGISTERED_AT: 'registeredAt',
  PARENT_USERNAME: 'parentUsername',
  TRADING_ACCOUNT: 'tradingAccount',
  EMAIL: 'email',
  CLIENT_EMAIL: 'clientEmail',
  BS_USER_EMAIL: 'bsUserEmail',
  TWO_FA: 'isTwoFactorAuthEnabled',
  REGISTRATION_DATE: 'registrationDate',
  ACCOUNT_CREATED_AT: 'accountCreatedAt',
  REG_SOURCE: 'regSource',
  REGISTRATIONS: 'registrations',
  TRADES_COUNT: 'tradesCount',
  APPROVED_AT: 'approvedAt',
  BS_USER_APPROVED_AT: 'bsUserApprovedAt',
  NET_FUNDING: 'netFunding',
  NET_DEPOSITS: 'netDeposits',
  NET_PROFIT: 'netProfit',
  NET: 'net',
  PNL_USD: 'pnlUsd',
  DROPDOWN: 'menu',
  ACCESS: 'access',
  ROLE: 'role',
  EDITABLE_STATUS: 'statusRole',
  EDITABLE_DROPDOWN: 'editableDropdown',
  COMMISSION_DATE: 'commissionDate',
  PARTNER_ID: 'partnerId',
  PARTNER_EMAIL: 'partnerEmail',
  PARTNER_NAME: 'partnerName',
  PARTNER_COUNTRY: 'partnerCountry',
  REMOVE_DROPDOWN: 'removeDropdown',
  MEMBERS: 'members',
  FIRST_TRADE_DATE: 'firstTradeDate',
  LAST_TRADE_DATE: 'lastTradeDate',
  AFFILIATE_ID: 'affiliateId',
  AFFILIATE_LINK: 'link',
  BANK_NAME: 'bankName',
  BALANCE: 'balance',
  BALANCE_PREVIOUS: 'prevBalance',
  DEPOSITS: 'deposits',
  CHANGE_TYPE: 'changesType',
  CHANGED_AT: 'createdAt',
  DESCRIPTION: 'description',
  CHANGED_BY: 'userId',
  DEPOSIT_METHOD: 'type',
  COMMENT: 'comment',
  USER_ID: 'userId',
  IP_ADDRESS: 'ipAddress',
  PARTNER_IB_ID: 'partnerIbId',
  PARTNER_IB_COMMISSION: 'partnerIbCommission',
  MASTER_IB_ID: 'masterIbId',
  MASTER_IB_COMMISSION: 'masterIbCommission',
  SELF_REBATE_COMMISSION: 'selfRebateCommission',
  IB_SELF_REBATE_COMMISSION: 'ibSelfRebateCommission',
  FIRST_DEPOSIT_DATE: 'firstDepositDate',
  IB_WITHDRAWALS: 'ibWithdrawals',
  WITHDRAW: 'withdraw',
  IB_ADJUSTMENTS: 'ibAdjustments',
  WITHDRAWALS: 'withdrawals',
  ADJUSTMENTS: 'adjustments',
  ACCOUNT_ID: 'accountId',
  CCY: 'ccy',
  ACCOUNT_TYPE: 'accountType',
  ACCOUNT_PLATFORM: 'accountPlatform',
  ACCOUNTS: 'accounts',
  MANAGER_EMAIL: 'managerEmail',
  PARTNER_REF_CODE: 'partnerRefCode',
  REF_CODE: 'refCode',
  PAYER_ID: 'payerId',
  PAYER_EMAIL: 'payerEmail',
  DEPOSIT: 'deposit',
  RESIDENTIAL_ADDRESS: 'residentialAddress',
  PF_LOT_VOLUME: 'pfLotVolume',
  USER_FULL_NAME: 'userFullName',
  PAYER_FULL_NAME: 'payerFullName',
  USER_COUNTRY: 'userCountry',
  PAYER_COUNTRY: 'payerCountry',
  LOT_TRADED: 'lotTraded',
  NET_PNL: 'netPnl',
  RAF_PROGRAM: 'rafProgram',
  MT4_ONLY: 'mt4Only',
};

export const TABLE_TITLE = {
  TWO_FA: '2FA',
  DATE_REGISTERED: 'Date Registered',
  TRADES_COUNT: 'Trades',
  TRADES_GROWTH: 'Trades Growth',
  REGISTRATION: 'Registration',
  PARTNER: 'Partner Email',
  FTD: 'FTD',
  LOGIN: 'Account Login',
  ID: 'ID',
  NAME: 'Name',
  COUNTRY: 'Country',
  TITLE: 'Title',
  PARTNER_COUNTRY: 'Partner Country',
  CLIENT_COUNTRY: 'Client Country',
  FUNDED_DATE: 'Funded Date',
  FIRST_TRADE_DATE: 'First Trade Date',
  LAST_TRADE_DATE: 'Last Trade Date',
  FIRST_COMMISSION_DATE: 'First Commission Date',
  FIRST_DEPOSIT: 'First Deposit',
  STATUS: 'Status',
  GROUP_CODE: 'Group Code',
  COMMISSION_EARNED: 'Commission Earned',
  DATE: 'Date',
  DATE_TIME: 'Date & Time',
  PAYMENT_METHOD: 'Payment Method',
  AMOUNT: 'Amount',
  OPEN_TIME: 'Open time',
  CLOSE_TIME: 'Close Time',
  SIZE: 'Size',
  TYPE: 'Type',
  SYMBOL: 'Symbol',
  OPEN_PRICE: 'Open price',
  CLOSE_PRICE: 'Close price',
  COMMISSION: 'Commission',
  PROFIT: 'Profit',
  ORDER: 'Order',
  SWAP: 'Swap',
  ORDER_NUMBER: 'Order Number',
  VOLUME: 'Volume',
  LOTS_AMOUNT: 'Lots Amount',
  DIRECTION: 'Direction',
  AUTHOR: 'Author',
  MANAGER_EMAIL: 'Manager Email',
  MESSAGE: 'Message',
  SOURCE_TYPE: 'Source type',
  CREATED: 'Created',
  LAST_LOGIN: 'Last Login',
  MANAGER: 'Manager',
  GROUP: 'Group',
  EARNINGS: 'Earnings',
  EARNINGS_GROWTH: 'Earnings Growth',
  LEADS_GROWTH: 'Leads Growth',
  LEADS: 'Leads',
  DROPDOWN: '',
  GROUP_ID: 'Group ID',
  GROUP_NAME: 'Group Name',
  GROUP_MEMBERS: 'Members',
  REGISTERED: 'Registered',
  REGISTRATIONS: 'Registrations',
  PARENT_USERNAME: 'Parent Username',
  TRADING_ACCOUNT: 'Trading Account',
  REGISTRATION_DATE: 'Registration date',
  ACCOUNT_CREATED_DATE: 'Account Created Date',
  REGISTRATION_SOURCE: 'Registration Source',
  APPROVAL_DATE: 'Approval Date',
  CLIENT_APPROVED_DATE: 'Client Approved Date',
  FIRST_DEPOSIT_DATE: 'First deposit date',
  NET_FUNDING: 'Net Funding',
  EMAIL: 'Email',
  CLIENT_EMAIL: 'Client Email',
  NET_PROFIT: 'Net profit',
  START_DATE: 'Start date',
  ACCESS: 'Access',
  COMMISSION_DATE: 'Commission Date',
  PARTNER_ID: 'Partner ID',
  PAYER_ID: 'Payer ID',
  PARTNER_EMAIL: 'Partner Email',
  USER_ID: 'User ID',
  IP_ADDRESS: 'IP Address',
  AFFILIATE_ID: 'Affiliate Id',
  AFFILIATE_LINK: 'Affiliate Link',
  BALANCE: 'Balance',
  BALANCE_PREVIOUS: 'Balance Previous',
  DEPOSITS: 'Deposits',
  NET_DEPOSITS: 'Net Deposits',
  CHANGE_TYPE: 'Change Type',
  CHANGED_AT: 'Changed At',
  DESCRIPTION: 'Description',
  COMMENT: 'Comment',
  CREATED_AT: 'Created',
  UPDATED_AT: 'Updated',
  IB_ID: 'IB ID',
  IB_COMMISSION: 'IB Commission',
  PARTNER_IB_ID: 'Partner IB ID',
  PARTNER_IB_COMMISSION: 'Partner IB Commission',
  MASTER_IB_ID: 'Master IB ID',
  MASTER_IB_COMMISSION: 'Master IB Commission',
  SELF_REBATE_COMMISSION: 'Self Rebate Commission',
  IB_SELF_REBATE_COMMISSION: 'Reduced Commission',
  PAYMENTS: 'Payments',
  COMMISSIONS: 'Commissions',
  ADJUSTMENTS: 'Adjustments',
  TIME_AND_DATE: 'Time/Date',
  BANK_NAME: 'Bank Name',
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_TYPE: 'Account Type',
  PLATFORM: 'Platform',
  CCY: 'CCY',
  ACCOUNT: 'Account',
  ACCOUNT_ID: 'Account ID',
  WITHDRAWALS: 'Withdrawals',
  REF_CODE: 'Ref Code',
  RESIDENTIAL_ADDRESS: 'Residential Address',
  LOTS_TRADED: 'Lots Traded',
  RAF_PROGRAM: 'RAF Program',
  MT4_ONLY: 'MT4 Only',
};

export const tableCell = {
  flag: (className) => (payload) =>
    payload ? (
      <Identifier className={className} name="Country" value={payload} />
    ) : null,
  formatted:
    ({
      maximumFractionDigits,
      minimumFractionDigits,
      style,
      useGrouping,
      group,
      preFormatted,
      className,
    }) =>
    (payload) =>
      (
        <TableColFormatted
          className={className}
          payload={Number(payload)}
          maximumFractionDigits={maximumFractionDigits}
          minimumFractionDigits={minimumFractionDigits}
          style={style}
          useGrouping={useGrouping}
          group={group}
          preFormatted={preFormatted}
        />
      ),
  date: (formatType, className) => (payload) =>
    (
      <TableColDate
        className={className}
        date={payload}
        formatType={formatType}
      />
    ),
  background:
    ({ icon, className, onClick, type, justify, label }) =>
    (text, record) =>
      BackgroundWrapper({
        text: label || text,
        className,
        icon,
        record,
        onClick,
        type,
        justify,
      }),
  status:
    ({ icon, className, onClick, type, justify }) =>
    (text, record) =>
      StatusWrapper({
        text,
        className,
        icon,
        record,
        onClick,
        type,
        justify,
      }),
  commissionEarned: (value) => (
    <TableColFormatted payload={value?.currentPeriod} style="currency" />
  ),
  entityChanging: (currentPeriodValue, entityGrowth, format) => (
    <TableColEntityGrowth
      currentPeriodValue={currentPeriodValue}
      entityGrowth={entityGrowth}
      cellFormat={format}
    />
  ),
  editableContainer: (rest) => (props, record) =>
    TableEditableCell({ record, ...rest, ...props }),
  selectorCell:
    ({ type, ...props }) =>
    (_value, record) =>
      SelectorCell({
        type,
        currentValue: record[type],
        record,
        ...props,
      }),
  capitalize: () => (payload) => <>{capitalize(lowerCase(payload))}</>,
  gst: () => (payload) =>
    <>{typeof payload !== 'boolean' ? '' : payload ? 'Enabled' : 'Disabled'}</>,
  title: () => (payload) =>
    <>{typeof payload !== 'boolean' ? '' : payload ? 'Invoice' : ''}</>,
  toUpper: () => (payload) => <>{toUpper(payload)}</>,
  startCase: () => (payload) => <>{startCase(lowerCase(payload))}</>,
  text: (props) => (value) => <Text {...props}>{value}</Text>,
  email: () => (value) =>
    (
      <Text ellipsis={{ tooltip: value }} style={{ maxWidth: 250 }}>
        {value}
      </Text>
    ),
  progressBar:
    ({ title }) =>
    (currentValue, maxValue) => {
      const roundedCurrentValue = roundToTwoDecimals(currentValue);
      const roundedMaxValue = roundToTwoDecimals(maxValue);

      const actualValue =
        (roundedCurrentValue < roundedMaxValue
          ? roundedCurrentValue
          : roundedMaxValue) || 0;

      return (
        <TableColProgressBar
          value={actualValue}
          max={roundedMaxValue || 0}
          title={title}
        />
      );
    },
  boolean: () => (payload) =>
    <>{typeof payload !== 'boolean' ? '--' : payload ? 'Yes' : 'No'}</>,
};
